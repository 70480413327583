<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card style="border-radius: 30px !important;">

      <v-row no-gutters align="center">

        <!-- Title + Close icon -->
        <v-col cols="12" class="mt-6">
          <v-row no-gutters>
            <v-col cols="8" class="offset-2">
              <h2 class="font-weight-bold text-center" style="margin:0 auto !important;">
                {{$t('account.profile.passwordDialog.title')}}</h2>
            </v-col>
            <v-col cols="1" class="offset-1">
              <v-btn icon @click="close(false)">
                <v-icon color="primary">fal fa-times</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="8" class="offset-md-2 mt-10">
          <v-form ref="form">
            <v-row no-gutters justify="center">

              <!-- Current password -->
              <v-col cols="12">
                <v-text-field
                  v-model="form.current_password"
                  :label="$t('account.profile.passwordDialog.form.currentPassword')"
                  type="password"
                  dense
                  hide-details
                  outlined>
                  <template v-slot:prepend>
                    <img width="20" src="@/assets/icons/controls/password.svg" />
                  </template>
                </v-text-field>
              </v-col>

              <!-- Btn generate password -->
              <v-col cols="12" md="10" offset-md="1" class="text-center mb-4 mt-6">
                <v-btn
                  color="white"
                  elevation="4"
                  class="px-6"
                  style="padding:0 !important;color: #4D59FF !important;width: 100% !important;"
                  @click="generatePassword">
                  <v-icon color="primary" class="ml-2">fas fa-shield-check</v-icon>
                  <span class="mx-2 mt-1">{{$t('account.profile.passwordDialog.buttons.generateRandomPassword')}}</span>
                </v-btn>
              </v-col>

              <!-- New password -->
              <v-col cols="12" class="mt-2">
                <v-text-field
                  dense
                  hide-details
                  v-model="form.password"
                  :label="$t('account.profile.passwordDialog.form.newPassword')"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'far fa-eye-slash' : 'far fa-eye'"
                  @click:append="showPassword = !showPassword"
                  outlined>
                  <v-tooltip
                    slot="append-outer"
                    bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">far fa-question-circle</v-icon>
                    </template>
                    <template v-for="(policy) in $t('account.registration.tooltips.password_rules')">
                      <div> - {{policy}}</div>
                    </template>
                  </v-tooltip>
                  <template v-slot:prepend>
                    <img width="20" src="@/assets/icons/controls/password.svg" />
                  </template>
                </v-text-field>
              </v-col>

              <!-- New password confirmation -->
              <v-col cols="12" class="mt-4">
                <v-text-field
                  dense
                  v-model="form.passwordConfirmation"
                  :label="$t('account.profile.passwordDialog.form.newPasswordConfirmation')"
                  type="password"
                  outlined>
                  <template v-slot:prepend>
                    <img width="20" src="@/assets/icons/controls/password.svg" />
                  </template>
                </v-text-field>
              </v-col>

              <!-- Alert bar -->
              <v-col cols="12">
                <AlertBar />
              </v-col>
            </v-row>

          </v-form>
        </v-col>

        <!-- Save btn -->
        <v-col cols="12" class="text-center my-6">
            <v-btn
              @click="customSubmit"
              rounded
              :disabled="!(!!form.password && !!form.passwordConfirmation)"
              width="150"
              color="primary"
              class="font-weight-bold"
              :loading="isLoadingBtn.save"
              style="border-radius: 10px !important;font-size: 16px !important;"
            >
              {{$t('common.buttons.save')}}
            </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>

</template>

<script>
  import generator from 'generate-password'
  // Mixins
  import dialog from "@/mixins/dialog"

  export default {
    name: "ProfileChangePasswordDialog",

    props: {
    },

    components: {
      ButtonBarFormDialog: () => import("@/components/Common/Dialogs/ButtonBarFormDialog"),
      AlertBar: () => import("@/components/Common/AlertBar")
    },

    mixins: [dialog],

    data() {
      return {

        showPassword: false,

        form: {
          current_password: "",
          password: "",
          passwordConfirmation: ""
        },

        isLoadingBtn: {
          save: false
        }
      }
    },

    methods: {

      generatePassword() {
        const generatedPassword = generator.generate(
        {
          length: 10,
          numbers: true
        });

        this.form.password = generatedPassword;
        this.form.passwordConfirmation = generatedPassword;

        this.showPassword = true;
      },

      customSubmit() {

        if (this.form.password === this.form.passwordConfirmation) {

          this.isLoadingBtn.save = true;
          this.$http.put(`/map/users/${this.$session.get('id')}/password`, this.form, {
            headers: {
              Authorization: "Bearer " + this.$session.get('jwt')
            }
          })
          .then(res => {
            this.$session.set("jwt", res.data.token);
            this.close(true);
          })
          .catch(err => {
            if (err && err.response && err.response.status === 400)
              this.$store.commit('alert/showError', this.$t('account.profile.passwordDialog.errors')[err.response.data.type]);
            else
              this.$store.commit('alert/showErrorAxios', err);
          })
          .finally(() => {
            this.isLoadingBtn.save = false;
          });
        }
        else
          this.$store.commit("alert/showError", this.$t('account.profile.passwordDialog.errors.WRONG_CONFIRMATION'));
      }
    }
  }
</script>

<style scoped>

</style>